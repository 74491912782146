.leftmenu_contents{
    display: flex;
    flex-direction: row;
    align-items: stretch;
    padding: 0px;

    width: 100vw;
    min-width: 1440px;
    min-height: calc(100vh - 119px);
    background: #F1F1F1;
}
