.edukeyword .tab_row{
    display: flex;
    flex-direction: row;
    border: 0.5px solid #EEEEEE;
}

.edukeyword .tab_row .tab_def_item{
    cursor: pointer;
    width: 100px;
    padding: 10px 20px;
    background-color: #F1F1F1;
    border: 0.5px solid #EEEEEE;

    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    /* line-height: 23px; */
    text-align: center;
    color: #262327;
}

.edukeyword .tab_row .tab_act_item{
    cursor: pointer;
    width: 100px;
    padding: 10px 20px;
    background-color: #FFFFFF;
    border: 0.5px solid #EEEEEE;

    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    /* line-height: 23px; */
    text-align: center;
    color: #262327;
}

.edukeyword table td, .edukeyword table th{
    border: 0.5px solid #EEEEEE;
    text-align: center;
    align-items: center;
}

.edukeyword table {
    border: 1px solid #EEEEEE;
}

.edukeyword table td input{
    margin: auto; width: 100%;
}

.sortrow{
    display: flex;
    flex-direction: row;
    gap:10px;
    justify-content: center;
}

.sortrow .up{
    cursor: pointer;
    width: 24px;
    height: 24px;
    background-image: url(/public/images/ico_sort_up.png);
    background-size: 24px 24px;
    border: 1px solid #262327;
}

.sortrow .down{
    cursor: pointer;
    width: 24px;
    height: 24px;
    background-image: url(/public/images/ico_sort_down.png);
    background-size: 24px 24px;
    border: 1px solid #262327;
}

.jobkey_list{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap:20px;
}

.job_grouplist{
    width: 300px;
    border: 1px solid #EEEEEE;
    background-color: #FFFFFF;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.job_grouplist .joggroupname{
    cursor: pointer;
}

.job_detail{
    box-sizing: border-box;
    flex: 1;
    border: 1px solid #EEEEEE;
    background-color: #FFFFFF;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.job_detail_head{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.job_detail_head .detail_label{
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    /* line-height: 23px; */
    text-align: center;
    color: #262327;
}

.job_detail_head .detail_tool{
    display: flex;
    flex-direction: row;
    gap:10px;
}

.job_detail_row{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

.job_detail_row .job_row_label{
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #262327;
    width: 100px;
}

.rmvtxtbtn{
    cursor: pointer;
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: red;
    width: 100px;
    padding: 10px;
    margin: auto;
}

.techkey_list{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap:20px;
}

.tech_grouplist{
    width: 400px;
    border: 1px solid #EEEEEE;
    background-color: #FFFFFF;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;   
}

.tech_grouplist .techgroupname{
    cursor: pointer;
}

.largetgroup .card_head_title{
    font-size: 16px;
}

.largetgroup .card_body{
    border: 0px;
    gap: 6px;
    max-height: 400px;
    overflow-y: scroll;
}

.largetgroup .card_body > div{
    gap:6px;
    width:100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.largetgroup .remove{
    cursor: pointer;
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: red;
    padding: 5px 10px;
}

.largetgroup .modify{
    cursor: pointer;
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #262327;
}