.detailcompany table tr td{
    text-align: center;
}

.partcompany table tr td{
    text-align: center;
}

.prtrequest table tr td{
    text-align: center;
}

.prtrequest table tbody tr{
    cursor: pointer;
}
.detailrequest .card_row{
    border: none;
}

.detailrequest table tr th{
    width: 200px;
}

.detailrequest table tr td{
    padding-left: 10px;
}
.prtmanager table tr td{
    text-align: center;
}

.prtmanager table tbody tr{
    cursor: pointer;
}

.detailmanager table tr th{
    width: 180px;
}

.detailmanager table tr td{
    padding-left: 10px;
}

.prtlinetok table tr td{
    text-align: center;
}

.prtlinetok table tbody tr{
    cursor: pointer;
}

.detaillinetok table tr th{
    width: 150px;
}