.mt-10 {
  margin-top: 10px;
}

.pt-10 {
  padding-top: 10px;
}

.ql-editor {
  min-height: 500px;
  overflow: auto;
  max-height: 1000px;
  overflow: auto;
}

.ql-clipboard {
  position: fixed !important;
  left: 50% !important;
  top: 50% !important;
  /* display: none; */
}

.html-editor {
  width: 100%;
  min-height: 1000px;
}
