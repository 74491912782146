body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif, "Noto Sans KR";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 100vw;
  height: 100vh;
}

/* common */
.invalid-feedback {
  color: #db2121;
  font-size: 11px;
  margin-top: 5px;
  white-space: pre-wrap;
}

.bg-1 {
  background: #f1f1f1;
}

.bg-2 {
  background: #fff0f0;
}

.bg-E {
  background: #eee;
}

.bg-y {
  background-color: #f9e000;
}

.bg-y2,
.hilight {
  background-color: #fff2cc;
}

.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

.d-Iblock {
  display: inline-block !important;
}

.d-flex {
  display: flex !important;
}

.align-center {
  align-items: center !important;
  display: flex;
  justify-content: center;
}

.align-items-center {
  align-items: center !important;
  display: flex;
}

.justify-content-center {
  display: flex;
  justify-content: center;
}

.justify-content-start {
  display: flex;
  justify-content: flex-start;
}

.justify-content-between {
  display: flex;
  align-content: center;
  justify-content: space-between;
}

.justify-content-evenly {
  display: flex;
  align-content: center;
  justify-content: space-evenly;
}

.flex-direction {
  flex-direction: column;
}

.flex-1 {
  flex: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.underline {
  text-decoration: underline;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-700,
.fw-bold {
  font-weight: 700 !important;
}

.fw-900 {
  font-weight: 900 !important;
}

.b-none {
  border: none !important;
}

.border-1 {
  border: 1px solid #eee;
}

.box-none {
  box-shadow: none !important;
}

.b-radius0 {
  border-radius: 0px !important;
}

.b-radius1 {
  border-radius: 1px !important;
}

.b-radius4 {
  border-radius: 4px !important;
}

.b-radius10 {
  border-radius: 10px !important;
}

.b-radius10rem {
  border-radius: 10rem !important;
}

.rotate180 {
  transform: rotate(180deg);
}

.rotate {
  transform: rotate(180deg);
}

.word-wrap-break {
  word-wrap: break-word;
}

.pre-wrap {
  white-space: pre-wrap;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.white-text {
  color: #fff !important;
}

.mute-text {
  color: #d5d8dc !important;
}

.dark-text {
  color: #454f64 !important;
}

.dark-text1 {
  color: #62676a !important;
}

.black-text {
  color: #000 !important;
}

.text-light-blue {
  color: #4e65a0;
}

.azure-text {
  color: #22c4f8;
}

.azure-light {
  color: #7adbfb;
}

.gray-text {
  color: #666 !important;
}

.green-text {
  color: green !important;
}

.danger-text {
  color: #db2121 !important;
}

.yellow-text {
  color: #fce8a2 !important;
}

.blue-text {
  color: #4877f6;
}

.ellipsis1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.c-default {
  cursor: default;
}

.pointer {
  cursor: pointer;
}

.gap-5 {
  gap: 5px;
}

.gap-10 {
  gap: 10px;
}

.gap-15 {
  gap: 15px;
}

.gap-20 {
  gap: 20px;
}

.gap-25 {
  gap: 25px;
}

.w-8px {
  width: 8px !important;
}

.w-9px {
  width: 9px !important;
}

.w-10px {
  width: 10px !important;
}

.w-11px {
  width: 11px !important;
}

.w-12px {
  width: 12px !important;
}

.w-13px {
  width: 13px !important;
}

.w-14px {
  width: 14px !important;
}

.w-15px {
  width: 15px !important;
}

.w-16px {
  width: 16px !important;
}

.w-17px {
  width: 17px !important;
}

.w-18px {
  width: 18px !important;
}

.w-19px {
  width: 19px !important;
}

.w-20px {
  width: 20px !important;
}

.w-21px {
  width: 21px !important;
}

.w-22px {
  width: 22px !important;
}

.w-23px {
  width: 23px !important;
}

.w-24px {
  width: 24px !important;
}

.w-25px {
  width: 25px !important;
}

.w-28px {
  width: 28px !important;
}

.w-36px {
  width: 36px !important;
}

.w-48px {
  width: 48px !important;
}

.w-55px {
  width: 55px !important;
}

.w-64px {
  width: 64px !important;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-11 {
  font-size: 11px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-32 {
  font-size: 32px !important;
}

.fs-40 {
  font-size: 40px !important;
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-block {
    display: block !important;
  }

  .fs-md-10 {
    font-size: 10px !important;
  }

  .fs-md-11 {
    font-size: 11px !important;
  }

  .fs-md-12 {
    font-size: 12px !important;
  }

  .fs-md-13 {
    font-size: 13px !important;
  }

  .fs-md-14 {
    font-size: 14px !important;
  }

  .fs-md-15 {
    font-size: 15px !important;
  }

  .fs-md-16 {
    font-size: 16px !important;
  }

  .fs-md-20 {
    font-size: 20px !important;
  }

  .fs-md-24 {
    font-size: 24px !important;
  }

  .fs-md-32 {
    font-size: 32px !important;
  }

  .fs-md-40 {
    font-size: 40px !important;
  }

  .w-md-10px {
    width: 10px !important;
  }

  .w-md-11px {
    width: 11px !important;
  }

  .w-md-12px {
    width: 12px !important;
  }

  .w-md-13px {
    width: 13px !important;
  }

  .w-md-14px {
    width: 14px !important;
  }

  .w-md-15px {
    width: 15px !important;
  }

  .w-md-16px {
    width: 16px !important;
  }

  .w-md-17px {
    width: 17px !important;
  }

  .w-md-18px {
    width: 18px !important;
  }

  .w-md-19px {
    width: 19px !important;
  }

  .w-md-20px {
    width: 20px !important;
  }

  .w-md-21px {
    width: 21px !important;
  }

  .w-md-22px {
    width: 22px !important;
  }

  .w-md-23px {
    width: 23px !important;
  }

  .w-md-24px {
    width: 24px !important;
  }

  .w-md-36px {
    width: 36px !important;
  }

  .w-md-64px {
    width: 64px !important;
  }

  .width-md-24 {
    width: 24rem !important;
  }

  .width-md-23 {
    width: 23rem !important;
  }

  .width-md-21 {
    width: 21rem !important;
  }

  .width-md-20 {
    width: 20rem !important;
  }

  .width-md-14 {
    width: 14rem !important;
  }

  .width-md-13 {
    width: 13rem !important;
  }

  .width-md-12 {
    width: 12rem !important;
  }

  .width-md-11 {
    width: 11rem !important;
  }

  .width-md-10 {
    width: 10rem !important;
  }

  .width-md-9 {
    width: 9rem !important;
  }

  .width-md-8 {
    width: 8rem !important;
  }

  .width-md-7 {
    width: 7rem !important;
  }

  .width-md-6 {
    width: 6rem !important;
  }

  .width-md-5 {
    width: 5rem !important;
  }

  .width-md-1 {
    width: 1rem !important;
  }
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

.mt--1 {
  position: relative;
  top: -1px;
}

.mt--2 {
  position: relative;
  top: -2px;
}

.mt--3 {
  position: relative;
  top: -3px;
}

.mt--4 {
  position: relative;
  top: -4px;
}

.mt--5 {
  position: relative;
  top: -5px;
}

.mt--6 {
  position: relative;
  top: -6px;
}

/* Firefox, Chrome, Opera */
/* ::placeholder { 
  color: #999999;
} */

/* Internet Explorer 10-11 */
/* :-ms-input-placeholder { 
  color: #999999;
} */

/* Microsoft Edge */
/* ::-ms-input-placeholder { 
  color: #999999;
} */
.ReactModal__Overlay {
  z-index: 9;
}

table {
  width: 100%;
}

/* input_Login */
.input_box {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;

  height: 40px;
  background: #fafafa;
  border: 1px solid #cccccc;
  border-radius: 4px;

  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.input_box:read-only {
  background-color: #eeeeee;
}

input[type="text"].input_Date {
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 38px 10px 14px;
  width: 160px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 4px;

  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  background-image: url(/public/images/ico_calendar.png);
  background-size: 24px 24px;
  background-repeat: no-repeat;
  background-position-x: calc(100% - 10px);
  background-position-y: center;
}

.input_Time {
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  width: 80px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 4px;

  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

input[type="number"],
input[type="text"] {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;

  height: 40px;
  background: #fafafa;
  border: 1px solid #cccccc;
  border-radius: 4px;

  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.disabled .input_Date,
.disabled .input_Time {
  background-color: #eeeeee !important;
  cursor: default;
}

input[type="radio"] {
  cursor: pointer;
  appearance: none;
  width: 24px;
  height: 24px;
  background-image: url(/public/images/ico_radio_off.png);
  background-size: 24px 24px;
  margin: 0px;
}

input[type="radio"]:checked {
  background-image: url(/public/images/ico_radio_on.png);
}

select {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 28px 10px 10px;
  gap: 10px;

  /* height: 40px; */
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 4px;

  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;

  appearance: none;
  background-image: url(/public/images/ico_arrow_down.png);
  background-size: 18px 18px;
  background-repeat: no-repeat;
  background-position-x: calc(100% - 5px);
  background-position-y: center;
}

select:hover {
  outline: none;
}

select:focus-visible {
  outline: none;
}

.search_input {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 40px 10px 0px;
  width: 400px;
  height: 40px;
  gap: 20px;
  background: #fafafa;
  border: 1px solid #cccccc;
  border-radius: 4px;
  position: relative;
}

.search_input input {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  background-color: transparent;
  border: none;
}

.search_input .search_btn {
  cursor: pointer;
  background-image: url(/public/images/ico_search.png);
  background-size: 40px 40px;
  position: absolute;
  right: 0px;
  width: 40px;
  height: 40px;
}

input:hover {
  outline: none;
}

input:focus-visible {
  outline: none;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-thumb {
  /* background-color: darkgrey; */
  background-color: rgba(0, 0, 0, 0.3);
  /* outline: 1px solid slategrey; */
}

/* date picker */
.react-datepicker__navigation--years-upcoming {
  left: 8px;
  padding-left: 4px !important;
}

.react-datepicker__navigation--years-upcoming::before {
  border-color: #ccc;
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: "";
  display: block;
  height: 10px;
  position: absolute;
  top: 14px;
  width: 10px;
  transform: rotate(315deg);
}

.react-datepicker__navigation--years-previous {
  left: 8px;
  padding-left: 4px !important;
}

.react-datepicker__navigation--years-previous::before {
  border-color: #ccc;
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: "";
  display: block;
  height: 10px;
  position: absolute;
  top: 0px;
  width: 10px;
  transform: rotate(135deg);
}

/*  */
.page_contents {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
  flex: 1;
  background: #f1f1f1;
  height: 100%;
  margin-bottom: 50px;
}

.card {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  gap: 20px;
  width: 100%;
  background: #ffffff;
  border-radius: 10px;
}

.card_head {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  width: 100%;
}

.card_head_title {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  text-align: center;
  color: #262327;
}

.card_head_tool {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 10px;
  z-index: 9;
}

.card_head_indicator {
  display: flex;
  flex-direction: column;
  font-size: 10px;
  font-weight: normal;
  gap: 2px;
}

.btn_normal {
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px 10px 20px;
  min-width: 80px;
  height: 40px;
  background: #f9e000;
  border-radius: 4px;

  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 1;
  color: #262327;
  width: fit-content;
}

.btn_normal.cancel {
  background: #cccccc;
}

.btn_remove {
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px 10px 20px;
  height: 40px;
  background: #ff0000;
  border-radius: 4px;
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 1;
  letter-spacing: -0.025em;
  color: #ffffff;
}

.btn_link {
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px 10px 20px;
  min-width: 80px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #262327;
  border-radius: 4px;

  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 1;
  color: #262327;
  width: fit-content;
}

.btn_edit {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: #f9e000;
  border-radius: 4px;

  background-image: url(/public/images/ico_pencil.png);
  background-size: 18px 18px;
  background-position: center;
  background-repeat: no-repeat;
}

.btn_option {
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  height: 40px;
  background: #cccccc;
  border-radius: 4px;

  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  /* line-height: 20px; */
  color: #262327;
}

.card_body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 100%;
}

table {
  width: -webkit-fill-available;
  border-collapse: collapse;
  border-top: 1px solid #eeeeee;
}

table tr {
  border-bottom: 1px solid #eeeeee;
}

table thead tr {
  cursor: auto;
}

table tr th {
  background: #f1f1f1;
  padding: 10px 5px;
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #666666;
}

table tr td {
  background: #ffffff;
  padding: 10px 5px;
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #262327;
}

table.alignleft tr td,
table.alignleft tr th {
  text-align: left !important;
  padding-left: 10px;
}

.field_label {
  padding: 10px;
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #262327;
  word-break: break-all;
}

label.field_label {
  width: 160px;
}

.field_label span {
  font-weight: 700;
  color: #ff0000;
}

textarea {
  resize: none;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 4px;

  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

textarea:hover {
  outline: none;
}

textarea:focus-visible {
  outline: none;
}

.radio_label {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 4px;

  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #262327;
}

.card_row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* border-width: 1px 0px;
  border-style: solid;
  border-color: #EEEEEE; */
  border-top: 1px solid #eeeeee;
}

.card_row_col {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.card_row_item {
  display: flex;
  gap: 10px;
}

.card_row_val {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;
  gap: 10px;
  flex: 1;
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.pagenation {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px;
  gap: 4px;
  margin: 20px 0;
}

.page_nav_btn {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 32px;
  height: 32px;
  background: #f1f1f1;

  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #999999;
}

.page_act_btn {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 32px;
  height: 32px;
  background: #262327;

  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
}

.page_first_btn {
  background-image: url(./assets/ico_page_first.png);
  background-position: center;
  background-size: 32px 32px;
  background-repeat: no-repeat;
}

.page_prev_btn {
  background-image: url(./assets/ico_page_prev.png);
  background-position: center;
  background-size: 32px 32px;
  background-repeat: no-repeat;
}

.page_next_btn {
  background-image: url(./assets/ico_page_next.png);
  background-position: center;
  background-size: 32px 32px;
  background-repeat: no-repeat;
}

.page_last_btn {
  background-image: url(./assets/ico_page_last.png);
  background-position: center;
  background-size: 32px 32px;
  background-repeat: no-repeat;
}

.table_total {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #262327;
}

.table_total span {
  font-weight: 700;
}

.font_D700_14 {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #262327;
}

.font_D400_14 {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #262327;
}

.font_D700_16 {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #262327;
}

.frjustify_between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.fralign_center {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.fralign_start {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.fr_c_end {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.fcjust_center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.splite_head {
  border-top: 6px solid #eeeeee;
}

.splite_block {
  border-top: 3px solid #eeeeee;
}

.query_block {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  gap: 10px;
  background: #f1f1f1;
}

.close_btn {
  cursor: pointer;
  width: 24px;
  height: 24px;
  background-image: url(/public/images/ico_x.png);
  background-size: 24px 24px;
  background-position: center;
  background-repeat: no-repeat;
}

.query_block .sub_query_mark {
  width: 24px;
  height: 24px;
  background-image: url(/public/images/ico_child_sub_mark.png);
  background-size: 24px 24px;
  background-position: center;
  background-repeat: no-repeat;
}

.remove_btn {
  cursor: pointer;
  width: 32px;
  height: 32px;
  background-image: url(/public/images/ico_x_box.png);
  background-size: 24px 24px;
  background-position: center;
  background-repeat: no-repeat;
}

.plus_btn {
  cursor: pointer;
  width: 32px;
  height: 32px;
  background-image: url(/public/images/ico_add_box.png);
  background-size: 24px 24px;
  background-position: center;
  background-repeat: no-repeat;
}

.add_query {
  margin: auto;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 0px;
  gap: 4px;

  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #262327;
}

.cursor_pointer {
  cursor: pointer;
  text-decoration: underline;
}

.rtselect {
  border: 1px solid #cccccc;
  border-radius: 4px;
  box-sizing: border-box;
  height: 40px;
}

.rtselect .rtselect__input {
  max-height: 38px;
  /* display: none; */
  pointer-events: none;
  z-index: -1;
}

.rtselect .rtselect__control {
  max-height: 40px;
  box-sizing: border-box;
  border: none;
  box-shadow: none;
}

.rtselect .rtselect__indicators {
  margin: 0px;
  padding: 0px;
  padding-left: 10px;
}

.rtselect .rtselect__value-container {
  margin: 0px;
  padding: 0px;
  padding-left: 10px;
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.025em;
  color: #000000;
}

.rtselect .rtselect__input-container {
  margin: 0px;
  padding: 0px;
}

.rtselect .rtselect__indicator-separator {
  display: none;
}

.rtselect .rtselect__dropdown-indicator {
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(/public/images/ico_arrow_down.png);
  background-size: 18px 18px;
  background-repeat: no-repeat;
  background-position: center;
}

.rtselect .rtselect__dropdown-indicator svg {
  display: none;
}

.rtselect .rtselect__menu {
  gap: 0px;
}

/*  */
.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mx-1 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}

.mx-2 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.mx-5 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-0 {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

.my-1 {
  margin-bottom: 0.25rem !important;
  margin-top: 0.25rem !important;
}

.my-2 {
  margin-bottom: 0.5rem !important;
  margin-top: 0.5rem !important;
}

.my-3 {
  margin-bottom: 1rem !important;
  margin-top: 1rem !important;
}

.my-4 {
  margin-bottom: 1.5rem !important;
  margin-top: 1.5rem !important;
}

.my-5 {
  margin-bottom: 3rem !important;
  margin-top: 3rem !important;
}

.my-auto {
  margin-bottom: auto !important;
  margin-top: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

html:not([dir="rtl"]) .me-0 {
  margin-right: 0 !important;
}

[dir="rtl"] .me-0 {
  margin-left: 0 !important;
}

html:not([dir="rtl"]) .me-1 {
  margin-right: 0.25rem !important;
}

[dir="rtl"] .me-1 {
  margin-left: 0.25rem !important;
}

html:not([dir="rtl"]) .me-2 {
  margin-right: 0.5rem !important;
}

[dir="rtl"] .me-2 {
  margin-left: 0.5rem !important;
}

html:not([dir="rtl"]) .me-3 {
  margin-right: 1rem !important;
}

[dir="rtl"] .me-3 {
  margin-left: 1rem !important;
}

html:not([dir="rtl"]) .me-4 {
  margin-right: 1.5rem !important;
}

[dir="rtl"] .me-4 {
  margin-left: 1.5rem !important;
}

html:not([dir="rtl"]) .me-5 {
  margin-right: 3rem !important;
}

[dir="rtl"] .me-5 {
  margin-left: 3rem !important;
}

html:not([dir="rtl"]) .me-auto {
  margin-right: auto !important;
}

[dir="rtl"] .me-auto {
  margin-left: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

html:not([dir="rtl"]) .ms-0 {
  margin-left: 0 !important;
}

[dir="rtl"] .ms-0 {
  margin-right: 0 !important;
}

html:not([dir="rtl"]) .ms-1 {
  margin-left: 0.25rem !important;
}

[dir="rtl"] .ms-1 {
  margin-right: 0.25rem !important;
}

html:not([dir="rtl"]) .ms-2 {
  margin-left: 0.5rem !important;
}

[dir="rtl"] .ms-2 {
  margin-right: 0.5rem !important;
}

html:not([dir="rtl"]) .ms-3 {
  margin-left: 1rem !important;
}

[dir="rtl"] .ms-3 {
  margin-right: 1rem !important;
}

html:not([dir="rtl"]) .ms-4 {
  margin-left: 1.5rem !important;
}

[dir="rtl"] .ms-4 {
  margin-right: 1.5rem !important;
}

html:not([dir="rtl"]) .ms-5 {
  margin-left: 3rem !important;
}

[dir="rtl"] .ms-5 {
  margin-right: 3rem !important;
}

html:not([dir="rtl"]) .ms-auto {
  margin-left: auto !important;
}

[dir="rtl"] .ms-auto {
  margin-right: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.px-5 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.py-0 {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.py-1 {
  padding-bottom: 0.25rem !important;
  padding-top: 0.25rem !important;
}

.py-2 {
  padding-bottom: 0.5rem !important;
  padding-top: 0.5rem !important;
}

.py-3 {
  padding-bottom: 1rem !important;
  padding-top: 1rem !important;
}

.py-4 {
  padding-bottom: 1.5rem !important;
  padding-top: 1.5rem !important;
}

.py-5 {
  padding-bottom: 3rem !important;
  padding-top: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

html:not([dir="rtl"]) .pe-0 {
  padding-right: 0 !important;
}

[dir="rtl"] .pe-0 {
  padding-left: 0 !important;
}

html:not([dir="rtl"]) .pe-1 {
  padding-right: 0.25rem !important;
}

[dir="rtl"] .pe-1 {
  padding-left: 0.25rem !important;
}

html:not([dir="rtl"]) .pe-2 {
  padding-right: 0.5rem !important;
}

[dir="rtl"] .pe-2 {
  padding-left: 0.5rem !important;
}

html:not([dir="rtl"]) .pe-3 {
  padding-right: 1rem !important;
}

[dir="rtl"] .pe-3 {
  padding-left: 1rem !important;
}

html:not([dir="rtl"]) .pe-4 {
  padding-right: 1.5rem !important;
}

[dir="rtl"] .pe-4 {
  padding-left: 1.5rem !important;
}

html:not([dir="rtl"]) .pe-5 {
  padding-right: 3rem !important;
}

[dir="rtl"] .pe-5 {
  padding-left: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.375rem !important;
  border-radius: var(--cui-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.25rem !important;
  border-radius: var(--cui-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: 0.375rem !important;
  border-radius: var(--cui-border-radius) !important;
}

.rounded-3 {
  border-radius: 0.5rem !important;
  border-radius: var(--cui-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: 1rem !important;
  border-radius: var(--cui-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: 2rem !important;
  border-radius: var(--cui-border-radius-2xl) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.quill {
  width: 100%;

  .ql-container {
    /* min-height: 500px; */
  }

  .ql-blank {
    min-height: 500px;
  }
}
