.App {
  text-align: center;
}

.dashboard table tbody tr th{
  padding: 10px;
  text-align: left;
  width: 200px;
}

.dashboard table thead tr th{
  text-align: center;
}

.dashboard table tbody tr td{
  text-align: center;
}

.chart_title{
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  color: #262327;

  
  
  
}

.highcharts-credits{
  display: none;
}