
.eduleasson table tbody tr{
    cursor: pointer;
}

.eduleasson table tbody tr td{
    text-align: center;
}

.eduleasson .high_light{
    color: rgb(64, 114, 196);
}

.step_row{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
}

.step_row .act_step_num{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 9px;
    gap: 10px;
    width: 32px;
    height: 32px;
    background: #262327;
    border-radius: 100px;

    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    /* line-height: 23px; */
    text-align: center;
    color: #FFFFFF;
}

.step_row .def_step_num{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 9px;
    gap: 10px;
    width: 32px;
    height: 32px;
    background: #CCCCCC;
    border-radius: 100px;

    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    /* line-height: 23px; */
    text-align: center;
    color: #FFFFFF;
}

.step_row .split{
    width: 20px;
    height: 2px;
    background: #D9D9D9;
}

.prod_img_bg{
    border: 1px solid #EEEEEE;
    border-radius: 6px;
    width: 100%;
    overflow: hidden;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

