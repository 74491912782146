.reportservice .rtselect{
    width: 200px;
}

.reportservice .viewdate_area{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid #EEEEEE;
    border-radius: 5px;
}

.reportservice .viewdate_area .prev_date{
    width: 40px;
    height: 40px;
    cursor: pointer;
    background-image: url(/public/images/ico_prev.png);
    background-size: 24px;
    background-position: center;
    background-repeat: no-repeat;
}

.reportservice .viewdate_area .next_date{
    width: 40px;
    height: 40px;
    cursor: pointer;
    background-image: url(/public/images/ico_next.png);
    background-size: 24px;
    background-position: center;
    background-repeat: no-repeat;
}

.reportservice .react-datepicker__year--container{
    width: 180px;
}

.reportservice .react-datepicker__year--container .react-datepicker__year-text{
    width: auto;
    flex:1;
}

.reportservice table tr td{
    text-align: center;
}
.reportservice table tr td:first-child{
    width: 100px;
}

.detailAdvert table tr td{
    text-align: center;
}

.detailAdvert table tr .fieldhead{
    width: 160px;
}