.cmtlist table tr{
    cursor: pointer;
}
.cmtlist table tr td{
    text-align: center;
}

.detailcommunity table tr th{
    width: 120px;
}
.detailcommunity table tr td{
    text-align: center;
}

.detailcommunity .imgbg{
    height: 100px;
    border: 1px solid #EEEEEE;
}

.detailcomment table tr th{
    width: 120px;
}
.detailcomment table tr td{
    text-align: center;
}

.detailcomment .imgbg{
    height: 100px;
    border: 1px solid #EEEEEE;
}