.memlist table tr td{
    text-align: center;
}
.detailmember table tr td{
    text-align: center;
}

.detailmember table.alignleft tr th{
    width: 200px;
}

.detailmember .tab_act{
    cursor: pointer;
    padding: 5px 10px;
    background-color: #CCCCCC;
    width: 60px;
    text-align: center;
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #262327;
}

.detailmember .tab_nor{
    cursor: pointer;
    padding: 5px 10px;
    background-color: #FFFFFF;
    width: 60px;
    text-align: center;
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #262327;
}

.outlist table tr td{
    text-align: center;
}