.menu_root{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 1px;
    width: 200px;
    min-width: 200px;
    min-height: calc(100vh - 119px);
    background: #262327;

    
    
    
    /* overflow-y: scroll; */
}