.alermlist table tr th{
    text-align: left;
    padding: 10px 14px;
}
.alermlist table tr th:first-child, .alermlist table tr td:first-child{
    width: -webkit-fill-available;
}

.alermlist table tr th:last-child, .alermlist table tr td:last-child{
    width: 120px;
}
.alermlist table tr td {cursor: pointer;}
.alermlist tr td.on {
    background-color: #E2F0D9;
}

.mypage table tr th{
    width: 200px;
    padding: 10px;
    text-align: left;
}
.mypage table tr td{
    padding: 10px;
}

.passwordbox{
    position: relative;
    width: 300px;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.passwordbox .err_msg{
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: red;
}

.passwordbox img{
    position: absolute;
    right: 10px;
    top:8px;
    width: 24px;
    height: 24px;
    background-color: transparent;
}