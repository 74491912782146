.bannerMain table tr td{
    text-align: center;
}

.bannerMain .banner_img{
    margin: auto;
    height: 100px;
    border: 1px solid #EEEEEE;
}

.detailMain .pc_img_bg{
    margin-top: 10px;
    height: 200px;
}

.detailMain .mobile_img_bg{
    margin-top: 10px;
    height: 100px;
}

.detailMain table tr th{
    width: 120px;
}

.detailMain table tr td{
    padding-left: 10px;
}

.bannermainlist table tr{
    cursor: pointer;
}

.bannermainlist table tr td{
    text-align: center;
}

.banneradvert table tr{
    cursor: pointer;
}
.banneradvert table tr td{
    text-align: center;
}

.banneradvert .banner_img{
    margin: auto;
    height: 100px;
}