.othjobpaper table tr td{
    text-align: center;
}

.detailJobpaper .addtion_img{
    height: 100px;
    border-radius: 4px;
    overflow: hidden;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.detailJobpaper table tr th{
    width: 100px;
}