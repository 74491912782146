.fix_top{
    position: fixed;
    top:0px;
    left:0px;
}

.top_navi{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 40px;
    gap: 20px;

    width: 100vw;
    height: 60px;
    background: #FFFFFF;
    border-bottom: 1px solid #EEEEEE;
    z-index: 99;
    
    
    
}

.menu_showbtn{
    width: 24px;
    height: 24px;
    cursor: pointer;
    background-image: url(../../assets/ico_menu.png);
    background-position: center;
    background-size: 32px 32px;
    background-repeat: no-repeat;
    margin-top: 3px;
}
.top_head{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 10px;
    cursor: pointer;
}

.head_mark{
    width: 87px;
}

.head_user{
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #262327;
    margin-top: 3px;
}

.top_tool{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 20px;
}

.topToolBtn{
    position: relative;
}

.top_alerm{
    position: relative;
    width: 32px;
    height: 32px;
    background-image: url(/public/images/ico_alerm.png);
    background-size: 32px 32px;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}

.top_alerm_count{
    position: absolute;
    left: 17px;
    top: 0px;
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: white;
    background: #ed0000;
    padding: 3px 4px;
    border-radius: 100px;
    width: min-content;
}

.top_avatar{
    cursor: pointer;
    width: 40px;
    height: 40px;
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    border-radius: 100px;
    overflow: hidden;
    background-image: url(/public/images/ico_emp_avatar.png);
    background-size: 40px 40px;
    background-repeat: no-repeat;
    background-position: center;
}

.popupRoot{
    position: absolute;
    right: 0px;
    top: 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    background: #FAFAFA;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    z-index: 99;
}
.popupRoot.profile {
    padding: 15px;
    gap: 10px;
}

.popupitem{
    cursor: pointer;
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: -0.025em;
    color: #000000;
    width: max-content;
}

.fcm_item{
    cursor: pointer;
    width: 400px;
    display: flex;
    flex-direction: row;
    padding: 10px;
    gap: 6px;
    border-bottom: 1px solid #CCC;
}
.fcm_item.on {background-color: #E2F0D9;}

.fcm_msg{
    flex: 1;
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
}

.fcm_time{
    width: 60px;
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #000000; text-align: right;
}

.all_list_view{
    cursor: pointer;
    width: 100%;
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #000000;
}