.loginpage {
  background-color: #F9E000;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
}

.loginform {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 360px;
  padding: 0px;
  gap: 30px;
}

.formtitle {
  font-family: 'Noto Sans KR';
  font-weight: 900;
  font-size: 48px;
  line-height: 70px;
  color: #262327;
}

.login_inp_area {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 360px;
}

.login_tool {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
  width: 100%;
}

.btn_rectangle {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  background: #262327;
  border-radius: 4px;

  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #F9E000;
}

.btn_findpsw {
  cursor: pointer;
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  width: 100%;

  color: #262327;
}

.findpagedes{
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #000000;
}

.emailtitle{
  font-family: 'Noto Sans KR';
  font-weight: 900;
  font-size: 18px;
  line-height: 26px;
  color: #262327;
}

.loginpage .input_box{
  width: 100%;
}